import { useEffect, useState } from "react";
import { UserRole } from "../../models/";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useAuthentication } from "../../hooks/useAuthentication";

// For the alternate routes that can be accessed by Query Users as it doesn't have all the access as Admin User.
const QueryUserGuard = (props: any) => {
  const { children } = props;
  const [isQueryUser, setIsQueryUser] = useState(false);

  const auth = useAuthentication();

  const userInfo = useUserInfo();
  useEffect(() => {
    checkIsQueryUser();
  }, []); // eslint-disable-line

  function checkIsQueryUser() {
    if (auth.IsAuthenticated && userInfo.IsInRole(UserRole.Query))
      setIsQueryUser(true);
    else setIsQueryUser(false);
  }

  return isQueryUser ? <>{children}</> : <></>;
};

export default QueryUserGuard;
