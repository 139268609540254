import { useEffect, useState } from "react";
import { UserRole } from "../../models";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useAuthentication } from "../../hooks/useAuthentication";
import UserAuthenticationGuard from "./UserAuthenticationGuard";

interface IServiceUserGuardProps {
  children: React.ReactNode;
}

// For routes that should not be able to be accessed by OEM Admins or Service Users
const ServiceUserGuard = (props: IServiceUserGuardProps) => {
  return (
    <UserAuthenticationGuard>
      <OEMValidateServiceUser {...props} />
    </UserAuthenticationGuard>
  );
};

export default ServiceUserGuard;

function OEMValidateServiceUser(props: IServiceUserGuardProps) {
  const { children } = props;
  const [isServiceUser, setIsServiceUser] = useState(false);
  const userInfo = useUserInfo();

  const auth = useAuthentication();

  useEffect(() => {
    checkUserAuthentication();
  }, []); // eslint-disable-line

  function checkUserAuthentication() {
    if (auth.IsAuthenticated && userInfo.IsInRole(UserRole.ServiceUser))
      setIsServiceUser(true);
    else setIsServiceUser(false);
  }

  return isServiceUser ? <>{children}</> : <></>;
}
