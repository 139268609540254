import { useEffect, useRef, HTMLProps } from "react";

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " form-check-input"}
      onClick={(event: any) => {
        event.stopPropagation();
      }}
      {...rest}
    />
  );
}
