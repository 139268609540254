import { FeatureId, ISubscriptionInfo, UserRole } from "../../models";
import ODataIcon from "../../assets/img/icons/ODataRoute.svg?react";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { IFlagsmithFeature } from "flagsmith/types";

enum SidebarOptions {
  Overview = "Overview",
  Connections = "Connections",
  ClientTools = "Client Tools",
  DataExplorer = "Data Explorer",
  Datasets = "Virtual Datasets",
  Jobs = "Jobs",
  Users = "Users",
  OData = "OData",
  Logs = "Logs",
  Api = "API",
  SupportImpersonation = "Support Impersonation",
}

export interface IRoutePath {
  name: SidebarOptions;
  icon: JSX.Element;
  path: string;
  showSidebarItem: boolean;
  isNew: boolean;
  testId?: string;
}

const getSidebarOptionsByRole = (role: UserRole): SidebarOptions[] => {
  const {
    Overview,
    Connections,
    ClientTools,
    DataExplorer,
    Datasets,
    Jobs,
    Users,
    OData,
    Logs,
    Api,
    SupportImpersonation,
  } = SidebarOptions;

  switch (role) {
    case UserRole.Admin:
      return [
        Overview,
        Connections,
        ClientTools,
        DataExplorer,
        Datasets,
        Jobs,
        Users,
        OData,
        Logs,
        Api,
        SupportImpersonation,
      ];
    case UserRole.Query:
      return [Connections, ClientTools, DataExplorer, Datasets, Logs, Api];
    case UserRole.OEMAdmin:
      return [Overview, Connections, Logs];
    case UserRole.ServiceUser:
      return [Overview, Connections, Logs];
    default:
      return [];
  }
};

const getMasterRoutePathList = (
  role: UserRole,
  subscription: ISubscriptionInfo,
  flags: Record<string, IFlagsmithFeature>,
): IRoutePath[] => {
  const {
    Overview,
    Connections,
    ClientTools,
    DataExplorer,
    Datasets,
    Jobs,
    Users,
    OData,
    Logs,
    Api,
    SupportImpersonation,
  } = SidebarOptions;

  const isCachingEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );

  const isScheduledQueriesEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.ScheduledQueries,
  );

  const isOnlySpreadsheetClient = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.ConnectForSpreadsheets,
  );

  const isODataVisible =
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
      .isOdataVisible === true;

  const canImpersonateAsSupport =
    flags.impersonation.enabled &&
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
      .canImpersonateAsSupport === true;

  return [
    {
      name: Overview,
      icon: <i className="fa-regular fa-wave-pulse fa-lg" />,
      path: "/",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: Connections,
      icon: <i className="fa-regular fa-database fa-lg" />,
      path: "/connections",
      showSidebarItem: true,
      isNew: false,
      testId: "connections-sidebar-link",
    },
    {
      name: ClientTools,
      icon: <i className="fa-regular fa-link fa-lg" />,
      path: role === UserRole.Admin ? "/clients" : "/",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: DataExplorer,
      icon: <i className="fa-regular fa-magnifying-glass fa-lg" />,
      path: "/data-explorer",
      showSidebarItem: !isOnlySpreadsheetClient,
      isNew: false,
    },
    {
      name: Datasets,
      icon: <i className="fa-regular fa-folder fa-lg" />,
      path: "/datasets",
      showSidebarItem: !isOnlySpreadsheetClient,
      isNew: false,
    },
    {
      name: Jobs,
      icon: <i className="fa-regular fa-clone fa-lg" />,
      path: "/jobs",
      showSidebarItem:
        (isCachingEnabled || isScheduledQueriesEnabled) &&
        !isOnlySpreadsheetClient,
      isNew: true,
    },
    {
      name: Users,
      icon: <i className="fa-regular fa-user fa-lg" />,
      path: "/user",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: OData,
      icon: (
        <ODataIcon className="fa-regular fa-lg" aria-description="OData logo" />
      ),
      path: "/odata",
      showSidebarItem:
        Boolean(flags.odata_client.enabled) && Boolean(isODataVisible),
      isNew: false,
    },
    {
      name: Logs,
      icon: <i className="fa-regular fa-file-lines fa-lg" />,
      path: "/logs",
      showSidebarItem: true,
      isNew: false,
    },
    {
      name: Api,
      icon: <i className="fa-regular fa-code fa-lg" />,
      path: "https://cloud.cdata.com/docs/API.html",
      showSidebarItem: !isOnlySpreadsheetClient,
      isNew: false,
    },
    {
      name: SupportImpersonation,
      icon: <i className="fa-regular fa-file-lines fa-user-headset" />,
      path: "/impersonation",
      showSidebarItem:
        Boolean(canImpersonateAsSupport) && !isOnlySpreadsheetClient,
      isNew: false,
    },
  ];
};

const getRoutePaths = (
  role: UserRole,
  subscription: ISubscriptionInfo,
  flags: Record<string, IFlagsmithFeature>,
): IRoutePath[] => {
  const masterRoutePaths: IRoutePath[] = getMasterRoutePathList(
    role,
    subscription,
    flags,
  );
  const sidebarOptions: SidebarOptions[] = getSidebarOptionsByRole(role);

  return masterRoutePaths.filter((routePath) =>
    sidebarOptions.includes(routePath.name),
  );
};

export default getRoutePaths;
