import { useEffect, useState } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useNavigate } from "react-router-dom";

interface ICanImpersonateGuardProps {
  children: React.ReactNode;
}

// For routes that can only be accessed by Support Admin Users with Impersonation Permissions when logged in
const CanImpersonateGuard = (props: ICanImpersonateGuardProps) => {
  const navigate = useNavigate();
  const { children } = props;
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const flags = useFeatureFlags().getFlags(["impersonation"]);

  const auth = useAuthentication();

  useEffect(() => {
    checkUserAuthentication();
  }, []); // eslint-disable-line

  const impersonationEnabled = flags.impersonation.enabled;

  if (!impersonationEnabled) {
    navigate("/auth/Impersonation-Not-Allowed");
  }

  const userInfo =
    window.sessionStorage.getItem("userInfo") &&
    JSON.parse(window.sessionStorage.getItem("userInfo") || "{}");

  const canImpersonateAsSupport = userInfo.canImpersonateAsSupport === true;

  if (!canImpersonateAsSupport) {
    navigate("/auth/Impersonation-Not-Allowed");
  }

  function checkUserAuthentication() {
    if (auth.IsAuthenticated) setUserAuthenticated(true);
    else setUserAuthenticated(false);
  }

  return userAuthenticated && canImpersonateAsSupport ? <>{children}</> : <></>;
};

export default CanImpersonateGuard;
