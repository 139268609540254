import React from "react";
import { useAPI } from "./useAPI";
import { AxiosResponse } from "axios";

export enum RequestType {
  Get = 0,
  Delete = 1,
  Put = 2,
  Post = 3,
}

export enum BackendType {
  AccountService = 0,
  AdminUI = 1,
  AuditService = 2,
  LogService = 3,
  QueryRouter = 4,
  OpenAIService = 5,
}

export interface IAPI {
  /** API call to the backend with the Bearer token.
   * If the errorTitle is filled out, default error handling will occur. If the string is empty, it will ignore all normal error handling.
   */
  callAPI: (
    requestType: RequestType,
    url: string,
    errorTitle: string,
    payload?: any,
    _endPoint?: BackendType,
    header?: { [key: string]: string },
  ) => Promise<IAPIResponse>;
  downloadAPI: (
    url: string,
    errorTitle: string,
    queryId: string,
  ) => Promise<IAPIResponse>;
}

export interface IAPIResponse<TPayload = any> {
  status: number;
  payload?: TPayload;
  error?: any;
  headers?: AxiosResponse["headers"];
}

export function withAPI(WrappedComponent: any) {
  return function WithAPIFunctionality(props: any) {
    const api = useAPI();
    return (
      <WrappedComponent
        callAPI={api.callAPI}
        downloadAPI={api.downloadAPI}
        {...props}
      />
    );
  };
}
