import React, { useEffect, useState } from "react";
import { addUser } from "../../redux/actions/userActions";
import { UserRole } from "../../models/";
import { useAuthentication } from "../../hooks/useAuthentication";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import {
  getImpersonatingUserId,
  getIsSupportImpersonationActive,
  isImpersonating,
} from "../../services/userImpersonation";
import { prependApiPrefix } from "../../api/prependApiPrefix";
import { useNavigate } from "react-router-dom";
import { IUserExtended } from "../../bffmodels/IUserExtended";
import { useAppDispatch } from "../../redux/hooks";

function addImpersonationHeaders(headers: Record<string, string | undefined>) {
  if (isImpersonating()) {
    headers["X-CData-Impersonated-User"] = getImpersonatingUserId();
    if (getIsSupportImpersonationActive()) {
      // This header is used by Cloudflare to verify VPN status of the logged in user.
      headers["cdata-impersonation"] = "active";
    }
  }
}

// For routes that can only be accessed by authenticated users
const UserAuthenticationGuard = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState<IUserExtended | null>(null);
  const auth = useAuthentication();
  const featureFlags = useFeatureFlags();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo === null) {
      const asyncFn = async () => {
        if (await setCurrentUserInfo()) {
          const user = await getCurrentUserInfo();
          setUserInfo(user);
        }
      };
      asyncFn();
    }
  }, [auth.GetAccessTokenSilently, auth.User]); // eslint-disable-line

  function setStoreUserInfo(user: IUserExtended | null) {
    if (user == null) {
      window.sessionStorage.removeItem("userInfo");
    } else {
      window.sessionStorage.setItem("userInfo", JSON.stringify(user));
    }
  }

  async function setCurrentUserInfo() {
    setStoreUserInfo(null);
    const accessToken = await auth.GetAccessTokenSilently();

    //For SSO Login
    const nameSplit = auth.Name!.split(" ");
    const user: IUserExtended = {
      email: auth.Email,
      accountId: "",
      accountName: "",
      canResetPassword: false,
      emailVerifyCode: "",
      emailVerifyExpires: "",
      enabled: false,
      canBeImpersonated: false,
      userImpersonationId: "",
      firstName: "",
      id: "",
      inviteCode: "",
      inviteExpires: "",
      isInvite: false,
      lastName: "",
      pendingEmail: "",
      permissions: [],
      role: UserRole.Admin,
      workspacePermissions: [],
      canImpersonateAsSupport: false,
      isOdataVisible: false,
    };

    if (nameSplit.length > 1) {
      user.firstName = nameSplit[0];
      user.lastName = nameSplit[1];
    } else {
      user.firstName = nameSplit[0];
    }

    const data = {
      Email: auth.Email,
      Name: auth.Name,
      EmailVerified: auth.Email_verified,
      IsSSOLogin:
        auth.User![
          window.VITE_CONFIG.VITE_AUTH_CUSTOM_CLAIMS_NAMESPACE + "is_pass_login"
        ] !== true,
      IdToken: accessToken,
      AccessToken: accessToken,
      ExternalId:
        auth.User![
          window.VITE_CONFIG.VITE_AUTH_CUSTOM_CLAIMS_NAMESPACE + "external_id"
        ],
      User: user,
    };

    const requestOptions = {
      method: "Post",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        // The impersonation header is intentionally not sent in this request so the user is still logged in as themselves
      },
    };

    const signInInfo = data;
    let route = "";
    //SSO Login or Social Login starts here
    if (signInInfo.IsSSOLogin) {
      if (!signInInfo.EmailVerified) {
        /*Redirect the user, and mark the response as handled so that the OpenIdConnect middleware doesn't
                set the cookies that would cause them to remain authenticated.*/
        route = "/auth/verify-email";
        navigate(route);
        return;
      }
      const accountConnectResponse = await fetch(
        prependApiPrefix("/account/loginAccountSSO"),
        requestOptions,
      );
      const accountConnectResponseJson = await accountConnectResponse.json();
      const errorCode = accountConnectResponseJson?.error?.code;
      if (errorCode) {
        if (errorCode === "ACCOUNT_NOT_FOUND") {
          if (window.VITE_CONFIG.VITE_SIGNUP_URL) {
            window.location.href = window.VITE_CONFIG.VITE_SIGNUP_URL;
          } else {
            route = `/auth/sign-up-initial?email=${encodeURIComponent(signInInfo.Email ?? "")}&isSSO=true`;
          }
        } else if (errorCode === "USER_NOT_FOUND") {
          route = "/auth/user-sign-up?isUserFound=1";
        } else if (errorCode === "USER_EXISTS") {
          // force a relog of the user if we just merged the user identities in the auth provider
          route = "/account/logout";
        } else {
          route = "/auth/500";
        }
        navigate(route);
        return false;
      }
      return true;
    }

    /*Check whether the user's email has been verified by Auth. If not, they need to do that before
        anything else, so redirect them to a page which tells them that.*/
    if (!signInInfo.EmailVerified) {
      /*Redirect the user, and mark the response as handled so that the OpenIdConnect middleware doesn't
            set the cookies that would cause them to remain authenticated.*/
      navigate("/auth/verify-email");
      return false;
    }

    if (
      !signInInfo.ExternalId ||
      signInInfo.ExternalId === "MISSING_EXTERNAL_ID"
    ) {
      navigate("/auth/500");
      return false;
    }

    const loginResponse = await fetch(
      prependApiPrefix("/account/loginAccount"),
      requestOptions,
    );
    const loginResponseJson = await loginResponse.json();
    const errorCode = loginResponseJson?.error?.code;
    if (errorCode) {
      if (errorCode === "ACCOUNT_NOT_FOUND" || errorCode === "USER_NOT_FOUND") {
        if (window.VITE_CONFIG.VITE_SIGNUP_URL) {
          window.location.href = window.VITE_CONFIG.VITE_SIGNUP_URL;
        } else {
          route = `/auth/sign-up-initial?email=${encodeURIComponent(signInInfo.Email ?? "")}`;
        }
      } else if (errorCode === "USER_INVITED") {
        route = "/auth/user-sign-up";
      } else {
        route = "/auth/500";
      }
      navigate(route);
      return false;
    }
    return true;
  }

  async function getCurrentUserInfo() {
    try {
      const accessToken = await auth.GetAccessTokenSilently();

      const requestOptions: RequestInit = {
        method: "Get",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      addImpersonationHeaders(
        requestOptions.headers as Record<string, string | undefined>,
      );

      const response = await fetch(
        prependApiPrefix("/users/self"),
        requestOptions,
      );
      if (response.status === 200) {
        const user: IUserExtended = await response.json();
        dispatch(addUser(user));
        setStoreUserInfo(user);
        featureFlags.setIdentity(user.id, user.email, user.accountId);
        return user as IUserExtended;
      } else {
        if (response.status === 401) {
          navigate("account/logout");
        }
      }
      return null;
    } catch (exception) {
      console.error(exception);
      return null;
    }
  }

  return userInfo !== null ? (
    <AuthorizeContext.Provider value={userInfo}>
      {children}
    </AuthorizeContext.Provider>
  ) : null;
};

export default withAuthenticationRequired(UserAuthenticationGuard);

export const AuthorizeContext = React.createContext(
  getStoreUserInfo() as IUserExtended, // default value
);

function getStoreUserInfo(): IUserExtended | null {
  const user = window.sessionStorage.getItem("userInfo");
  if (user) {
    return JSON.parse(user) as IUserExtended;
  }
  return null;
}
