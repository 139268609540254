import { useEffect, useState } from "react";
import { UserRole } from "../../models/";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useAuthentication } from "../../hooks/useAuthentication";

// For routes that can only be accessed by Admin Users when logged in
const AdminGuard = (props: any) => {
  const { children } = props;
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const userInfo = useUserInfo();

  const auth = useAuthentication();

  useEffect(() => {
    checkUserAuthentication();
  }, []); // eslint-disable-line

  function checkUserAuthentication() {
    if (auth.IsAuthenticated && userInfo.IsInRole(UserRole.Admin))
      setUserAuthenticated(true);
    else setUserAuthenticated(false);
  }

  return userAuthenticated ? <>{children}</> : <></>;
};

export default AdminGuard;
