import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "../../../components/Loader";
import {
  successMessage,
  openPersonalAccessTokenModal,
  openRegeneratePersonalAccessTokenModal,
  openDeletePersonalAccessTokenModal,
} from "./AccessTokenTabModals";
import { IModalFunctions } from "../../../layouts/Dashboard";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { withAPI, IAPI, RequestType } from "../../../components/withAPI";
import { IPersonalAccessToken } from "../../../models";
import { ColumnDef } from "@tanstack/react-table";
import ListTable from "../../../components/tables/ListTable";
import { orderBy } from "lodash";
import classNames from "classnames";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";

interface IAccessTokenProps extends IModalFunctions, IAPI {
  toggleModal: any;
}

interface IAccessTokenState {
  token: string;
  personalAccessTokens: IPersonalAccessToken[];
  processingRequest: boolean;
  loading: boolean;
  consentExpirationDate: string;
  isSupportImpersonationActive: boolean;
}

class AccessToken extends Component<IAccessTokenProps, IAccessTokenState> {
  columns: ColumnDef<IPersonalAccessToken>[];
  constructor(props: IAccessTokenProps) {
    super(props);
    this.createPersonalAccessToken = this.createPersonalAccessToken.bind(this);
    this.deletePersonalAccessToken = this.deletePersonalAccessToken.bind(this);
    this.getPersonalAccessTokens = this.getPersonalAccessTokens.bind(this);
    this.regeneratePersonalAccessToken =
      this.regeneratePersonalAccessToken.bind(this);
    this.setToken = this.setToken.bind(this);
    this.state = {
      token: "",
      personalAccessTokens: [],
      processingRequest: false,
      loading: true,
      consentExpirationDate: "",
      isSupportImpersonationActive: getIsSupportImpersonationActive(),
    };
    this.columns = [
      {
        accessorKey: "name",
        enableSorting: false,
        header: "Name",
        meta: {
          className: "name-col",
        },
        cell: ({ row }) => {
          return <>{row.original.name}</>;
        },
      },
      {
        accessorKey: "creationData",
        enableSorting: false,
        header: "Creation Date",
        cell: ({ row }) => {
          const createdDate = formatUTCDateTime(row.original.created!) + " UTC";
          let expirationDate = row.original.expirationDate
            ? formatUTCDateTime(row.original.expirationDate)
            : null;

          if (this.state.consentExpirationDate && row.original.expirationDate) {
            const consentExpirationDate = new Date(
              this.state.consentExpirationDate,
            );
            const tokenExpirationDate = new Date(row.original.expirationDate);

            if (consentExpirationDate < tokenExpirationDate) {
              expirationDate = formatUTCDateTime(
                this.state.consentExpirationDate,
              );
            }
          }

          return expirationDate ? (
            <span className="d-flex flex-column">
              <span className="hide-overflow-text">{createdDate}</span>
              <span className="expiration-date hide-overflow-text">
                {"Expires: " + expirationDate + " UTC"}
              </span>
            </span>
          ) : (
            <>{createdDate}</>
          );
        },
      },
      {
        accessorKey: "token",
        enableSorting: false,
        header: "Token",
        meta: {
          className: "token-col",
        },
        cell: ({ row }) => {
          return (
            <div className="token-row">
              <button
                type="button"
                onClick={() =>
                  openRegeneratePersonalAccessTokenModal(
                    this.props.setModal,
                    row.original.id ?? "",
                    this.props.toggleModal,
                    this.setToken,
                    this.regeneratePersonalAccessToken,
                  )
                }
                className="table-button"
                aria-label="regenerate token"
                data-testid="button-regenerate-pat"
              >
                <i className="fa-regular fa-arrows-rotate align-middle"></i>
              </button>
              <button
                type="button"
                onClick={() =>
                  openDeletePersonalAccessTokenModal(
                    this.props.setModal,
                    this.deletePersonalAccessToken,
                    this.props.toggleModal,
                    this.setToken,
                    row.original.id ?? "",
                  )
                }
                className="table-button"
                aria-label="delete token"
                data-testid="button-delete-pat"
              >
                <i className="fa-regular fa-xmark fa-lg align-middle"></i>
              </button>
            </div>
          );
        },
      },
    ];
  }

  public getComponentName() {
    return "pages-settings-AccessTokenTab-AccessTokenTab";
  }

  async componentDidMount() {
    await this.getPersonalAccessTokens();
    await this.getCurrentUserInfo();
  }

  async getPersonalAccessTokens() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      "/users/self/pats",
      "Failed to get personal access token list due to the following error:",
    );
    if (status === 200) {
      this.setState({
        personalAccessTokens: payload.personalAccessTokens,
        loading: false,
      });
    }
  }

  async getCurrentUserInfo() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      "/users/self",
      "Failed to get current user info due to the following error.",
    );
    if (status === 200) {
      this.setState({
        consentExpirationDate: payload.consentExpirationDate ?? "",
      });
    }
  }

  setToken(token: string) {
    this.setState({ token: token });
  }

  async createPersonalAccessToken(event: React.FormEvent) {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const values = Object.fromEntries(formData.entries());
    if (this.state.isSupportImpersonationActive) {
      values.name = "CData Support";
      values.expirationDate = new Date(
        new Date().getTime() + 24 * 60 * 60 * 1000,
      ).toISOString();
    } else if (values.name === "CData Support") {
      ToastrError(
        "Failed to create personal access token due to the following error:",
        "PAT name is reserved.",
      );
      return;
    }
    const { status, payload } = await this.props.callAPI(
      RequestType.Post,
      "/users/self/pats",
      "Failed to create personal access token due to the following error:",
      values,
    );
    if (status === 200) {
      const data = payload;
      let personalAccessTokens = [...this.state.personalAccessTokens];
      personalAccessTokens.push(data);

      personalAccessTokens = orderBy(personalAccessTokens, (p) => p.name);

      successMessage(
        data.tokenString,
        this.props.toggleModal,
        this.setToken,
        this.props.setModal,
        true,
      );
      this.setState({
        token: data.tokenString,
        personalAccessTokens: personalAccessTokens,
        processingRequest: false,
      });
    } else {
      this.setState({ processingRequest: false });
    }
  }

  async regeneratePersonalAccessToken() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Post,
      `/users/self/pats/${this.state.token}`,
      "Failed to regenerate personal access token due to the following error:",
    );
    if (status === 200) {
      successMessage(
        payload.tokenString,
        this.props.toggleModal,
        this.setToken,
        this.props.setModal,
        false,
      );
    } else {
      this.setState({ processingRequest: false });
    }
  }

  async deletePersonalAccessToken() {
    this.props.toggleModal();

    const personalAccessToken = this.state.personalAccessTokens.find(
      (token) => {
        return token.id === this.state.token;
      },
    );

    const { status } = await this.props.callAPI(
      RequestType.Delete,
      `/users/self/pats/${this.state.token}`,
      "Delete failed",
    );

    if (status === 200) {
      const personalAccessTokens = this.state.personalAccessTokens.filter(
        (token) => {
          return token.id !== this.state.token;
        },
      );
      ToastrSuccess(
        "Personal Access Token Deleted Successfully",
        `Your personal access token '${personalAccessToken?.name}' has been deleted successfully.`,
      );
      this.setState({
        personalAccessTokens: personalAccessTokens,
      });
    }
  }

  static renderAccountPage(instance: AccessToken) {
    const personalAccessTokens: IPersonalAccessToken[] =
      instance.state.personalAccessTokens;
    const isDisabled: boolean =
      instance.state.isSupportImpersonationActive &&
      personalAccessTokens.some((token) => token.name === "CData Support");
    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h4 className="mb-4">Personal Access Tokens</h4>
            </Col>
            <Col className="d-flex justify-content-end">
              <span
                id="createPATButtonWrapper"
                className="d-inline-block"
                style={{ cursor: isDisabled ? "" : "pointer" }}
              >
                <Button
                  type="button"
                  color="primary"
                  id="createPATButton"
                  className="card-actions float-end"
                  onClick={() =>
                    openPersonalAccessTokenModal(
                      instance.props.setModal,
                      instance.createPersonalAccessToken,
                      instance.props.toggleModal,
                    )
                  }
                  data-testid="button-open-pat-modal"
                  disabled={isDisabled}
                  style={{ pointerEvents: isDisabled ? "none" : "auto" }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="small-icon align-middle no-pointer-event"
                  />
                  Create PAT
                </Button>
              </span>

              {isDisabled && (
                <UncontrolledTooltip
                  placement="top"
                  target="createPATButtonWrapper"
                  trigger="hover"
                >
                  Support users can only have one active PAT at a time.
                </UncontrolledTooltip>
              )}
            </Col>
          </Row>
          <ListTable
            className={classNames("personal-access-token-table", {
              "empty-table": personalAccessTokens.length === 0,
            })}
            columns={instance.columns}
            data={personalAccessTokens}
            emptyTableMessage={
              "Click on Create PAT to generate personal access tokens for your current user"
            }
            enableFiltering={false}
            enablePagination={false}
            enableCheckboxes={false}
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    ) : (
      AccessToken.renderAccountPage(this)
    );

    return (
      <Container fluid className={`p-0 ${this.getComponentName()}`}>
        {contents}
      </Container>
    );
  }
}

export default withAPI(AccessToken);
