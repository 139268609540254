import { useEffect, useState } from "react";
import {
  faCheck,
  faDownload,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  BillingStatus,
  IBillingUsage,
  ISubscriptionInfo,
  IBillingItem,
} from "../../../models";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";

import { IModalFunctions } from "../../../layouts/Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { BackendType, RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import { connect } from "react-redux";
import { addSubscription, addUsage } from "../../../redux/actions";
import { Status } from "../../../utility/Status";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";
import { useLicenseModals } from "../../../hooks/useLicenseModals";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";

interface ILicenseInfoTabProps {
  addSubscription: Function;
  addUsage: Function;
}

function LicenseInfoTab(props: IModalFunctions & ILicenseInfoTabProps) {
  const api = useAPI();
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<ISubscriptionInfo | null>(null);
  const [billingUsageInfo, setBillingUsageInfo] = useState<IBillingUsage>();
  const [licenseList, setLicenseList] = useState<IBillingItem[]>();
  function getComponentName() {
    return "pages-settings-licenseinfotab-LicenseInfoTab";
  }

  const licenseModals = useLicenseModals(setSubscriptionInfo);

  useEffect(() => {
    async function getToken() {
      await getUsage();
    }
    getToken();
  }, []); // eslint-disable-line

  useEffect(() => {
    async function getToken() {
      await getLicenseList();
      props.addSubscription(subscriptionInfo);
      props.addUsage(billingUsageInfo);
    }
    getToken();
  }, [subscriptionInfo, billingUsageInfo]); // eslint-disable-line

  let licenseBody: any[] = [];

  if (licenseList != null && licenseList.length !== 0) {
    licenseBody = licenseList.map((license: IBillingItem) => {
      return {
        id: license?.id,
        name: license?.name,
        productKey: (
          <div className="truncate-text" key={license?.internalName}>
            {license?.internalName}
          </div>
        ),
        keyType: (
          <div className="truncate-text" key={license?.name}>
            {license?.name}
          </div>
        ),
        editButtons: (
          <>
            <button
              className={"table-button is-hidden"}
              onClick={() => {
                /* TODO in CLOUD-6877 */
              }}
              aria-label="edit license"
              data-testid="button-edit-license"
            >
              <i className="fa-regular fa-pen align-middle"></i>
            </button>
            <button
              onClick={() =>
                licenseModals.deleteLicenseConfirmation(license.internalName!)
              }
              className="table-button"
              aria-label="delete license"
              data-testid="button-delete-license"
            >
              <i className="fa-regular fa-xmark fa-lg align-middle"></i>
            </button>
          </>
        ),
      };
    });
  }

  const rowClasses = (row: any) => {
    return classnames("licenseRow-" + row.name);
  };

  const licenseColumns = [
    {
      dataField: "id",
      text: "",
      sort: false,
      hidden: true,
    },
    {
      dataField: "name",
      text: "Product Key",
      sort: true,
      hidden: true,
    },
    {
      dataField: "productKey",
      text: "Product Key",
      sort: true,
      sortFunc: (a: any, b: any, order: string) => {
        if (order === "asc") {
          return a.key.localeCompare(b.key);
        }
        return b.key.localeCompare(a.key);
      },
    },
    {
      dataField: "keyType",
      text: "Key Type",
      sort: true,
      sortFunc: (a: any, b: any, order: string) => {
        if (order === "asc") {
          return a.key.localeCompare(b.key);
        }
        return b.key.localeCompare(a.key);
      },
    },
    {
      dataField: "editButtons",
      text: "",
      sort: false,
    },
  ];

  const filteredLicenseBody = licenseBody
    ? licenseBody.filter((item) => item && item?.id !== undefined)
    : [];

  const licenseTable = (
    <BootstrapTable
      bootstrap4
      id="licenseTable"
      classes="license-table mt-n1 mb-0"
      bordered={false}
      keyField="name"
      columns={licenseColumns}
      data={filteredLicenseBody.length > 0 ? filteredLicenseBody : []}
      rowClasses={rowClasses}
    />
  );

  async function getLicenseList() {
    const licenseList: IBillingItem[] = [];
    if (subscriptionInfo != null) {
      licenseList.push(subscriptionInfo.plan!);
    }
    setLicenseList(licenseList);
  }

  async function getUsage() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/usage",
      "Failed to get account usage due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      setBillingUsageInfo(payload);
      props.addUsage(payload);
    }
  }

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      <div className="container-fluid content-row">
        <Row>
          <div className="col d-flex">
            <Card className="license-card">
              <CardBody>
                <Row className="mb-3">
                  <Col className="license-status">
                    <h4 className="heading"> License </h4>
                    <Status
                      className={
                        subscriptionInfo?.billingStatus !==
                        BillingStatus.Canceled
                          ? "ms-2 status"
                          : "mt-2"
                      }
                      status={subscriptionInfo?.billingStatus}
                    />
                  </Col>
                  <Col className="text-right">
                    <Button
                      onClick={() =>
                        (() => {
                          licenseModals.addLicenseConfirmation();
                        })()
                      }
                      className="float-end minor-margin-right btn-primary"
                      type="button"
                      color="primary"
                      data-testid="button-manage-billing"
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="sm"
                        className="align-middle me-2 no-pointer-event"
                      />
                      <span className="align-middle">Install New License</span>
                    </Button>
                  </Col>
                </Row>
                {licenseTable}
                <FormGroup
                  hidden={
                    subscriptionInfo?.billingStatus === BillingStatus.Canceled
                  }
                  className="mb-3"
                >
                  <h5 className="mb-3 margin license-exp">
                    {"License Expiration"}
                  </h5>
                  <div className="mb-2">
                    {subscriptionInfo?.nextBillTime
                      ? formatUTCDateTime(
                          subscriptionInfo.nextBillTime,
                          "MMMM Do, YYYY",
                        )
                      : null}
                  </div>
                </FormGroup>
                <FormGroup
                  hidden={
                    subscriptionInfo?.billingStatus === BillingStatus.Canceled
                  }
                  className="mb-0 row plan-contents"
                >
                  <div className="col-sm-2 license-info">
                    <h5 className="mb-0">Your License Includes:</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="billing-check align-middle me-2 no-pointer-event mb-1"
                      />
                      {subscriptionInfo?.limits?.userLimit +
                        (billingUsageInfo?.usersCount !== 1
                          ? " User Seats"
                          : " User Seat")}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="billing-check align-middle me-2 no-pointer-event mb-1"
                      />
                      {((subscriptionInfo?.limits?.dataSourceLimit ?? 0) < 0
                        ? "Unlimited"
                        : subscriptionInfo?.limits?.dataSourceLimit) +
                        (subscriptionInfo?.limits?.dataSourceLimit !== 1
                          ? " Data Sources"
                          : " Data Source")}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="billing-check align-middle me-2 no-pointer-event mb-1"
                      />
                      {((subscriptionInfo?.limits?.connectionLimit ?? 0) < 0
                        ? "Unlimited"
                        : subscriptionInfo?.limits?.connectionLimit) +
                        (subscriptionInfo?.limits?.connectionLimit !== 1
                          ? " Connections"
                          : "Connection")}
                    </div>
                    {subscriptionInfo?.plan?.description
                      ? subscriptionInfo?.plan?.description
                          .split("|")
                          .map((descriptionItem) => {
                            return (
                              <div key={descriptionItem}>
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="sm"
                                  className="billing-check align-middle me-2 no-pointer-event mb-1"
                                />
                                {descriptionItem}
                              </div>
                            );
                          })
                      : null}
                  </div>
                  {((subscriptionInfo?.purchasedAddons?.length ?? 0) > 0 ||
                    (subscriptionInfo?.includedAddons?.length ?? 0) > 0) && (
                    <div className="col-sm-2 license-addon-info">
                      <h5 className="mb-0">Add-Ons:</h5>
                      {subscriptionInfo?.includedAddons?.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="billing-check align-middle me-2 no-pointer-event mb-1"
                            />
                            {addOn.name} (Included)
                          </div>
                        );
                      })}
                      {subscriptionInfo?.purchasedAddons?.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="billing-check align-middle me-2 no-pointer-event mb-1"
                            />
                            {addOn.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </FormGroup>
              </CardBody>
            </Card>
          </div>
          <div className="col-auto d-flex">
            <Card>
              <CardBody className="sales-card-body">
                <Row>
                  <h2 className="mb-0">Update License</h2>
                </Row>
                <Row className="sales-prompt">
                  Looking to update your Connect Server license type? Contact
                  our Sales team today to assist you in your request!
                </Row>
                <span className="phone-icon-bg">
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    size="lg"
                    className="phone-icon"
                  />
                </span>
                <p className="sales-contact-info mb-0">
                  <b>Mon-Fri, 9am-6pm EST</b>
                  <br />
                  (800) 235-7250 (within US)
                  <br />
                  (919) 928-5214 (outside US)
                </p>
                <a
                  href={`mailto:${getSalesEmailAddress()}`}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="sm"
                    className="align-middle me-2 no-pointer-event"
                  />
                  Email Sales
                </a>
              </CardBody>
            </Card>
          </div>
        </Row>
        <Row>
          <div className="col-12">
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col>
                    <h4> Rows Usage </h4>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
    </Container>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addSubscription: (subscriptionInfo: ISubscriptionInfo) =>
      dispatch(addSubscription(subscriptionInfo)),
    addUsage: (billingUsageInfo: IBillingUsage) =>
      dispatch(addUsage(billingUsageInfo)),
  };
};

export default connect(
  (store: any) => ({
    subscriptionInfo: store.subscriptionInfo,
    billingUsageInfo: store.billingUsageInfo,
  }),
  mapDispatchToProps,
)(LicenseInfoTab);
