import { Container, Row, Col } from "reactstrap";
import { useIsConnectForSpreadsheets } from "../hooks/useIsConnectForSpreadsheets";

export function Footer() {
  const isConnectSpreadsheets = useIsConnectForSpreadsheets();

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="6" className="text-left">
            <ul className="list-inline">
              <li className="list-inline-item">
                <span>
                  <a
                    className="text-muted"
                    href={
                      isConnectSpreadsheets
                        ? "https://community.cdata.com/connect-spreadsheets-96"
                        : "https://www.cdata.com/support/submit.aspx"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support
                  </a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <a
                    className="text-muted"
                    href="https://www.cdata.com/company/legal/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <a
                    className="text-muted"
                    href="https://cloud.cdata.com/docs/terms-of-service.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                </span>
              </li>
              <li className="list-inline-item">
                <span>
                  <a
                    className="text-muted"
                    href="https://status.cdata.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Status
                  </a>
                </span>
              </li>
            </ul>
          </Col>
          <Col xs="6" className="text-end">
            <p className="mb-0">
              <span className="text-muted">
                &copy; {new Date().getFullYear()} CData Software, Inc. All
                rights reserved.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
